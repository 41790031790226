import ptBR from "./locales/pt-BR.json";
import it from "./locales/it.json";
import en from "./locales/en.json";
import zhCN from "./locales/zh-CN.json";

export default defineI18nConfig(() => ({
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  messages: {
    "pt-BR": ptBR,
    "zh-CN": zhCN,
    it,
    en,
  },
  numberFormats: {
    en: {
      currency: {
        style: "currency",
        currency: "USD",
      },
    },
    "pt-BR": {
      currency: {
        style: "currency",
        currency: "BRL",
      },
    },
    it: {
      currency: {
        style: "currency",
        currency: "EUR",
      },
    },
    "zh-CN": {
      currency: {
        style: "currency",
        currency: "CNY",
      },
    },
  },
}));
